import { applicationFormSlugs } from "utils/constants"

import {
  AttachmentType,
  Country,
  Currency,
  Curriculum,
  EmploymentType,
  Language,
  ModeOfLearning,
  OrganisationType,
  QualificationField,
  QualificationLevel,
  State,
  TeachingLevel,
  TeachingRole,
} from "../global/types"
export enum LanguageProficiencyChoices {
  ELEMENTARY = 1,
  LIMITED_WORKING,
  PROFESSIONAL_WORKING,
  FULL_PROFESSIONAL,
  NATIVE,
}

export enum AdditionalAnswerType {
  TEXT = 1,
  EMAIL,
  REFERENCE,
}
export enum QuestionType {
  SINGLE_SELECT = 1,
  MULTIPLE_SELECT,
}

export enum ApplicationStatus {
  NOT_STARTED = "not_started",
  IN_PROGRESS = "in_progress",
  SUBMITTED = "submitted",
  UNDER_REVIEW = "under_review",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  ABANDONED = "abandoned",
}

export enum ReviewStatus {
  NOT_STARTED = "not_started",
  REVIEW_STARTED = "review_started",
  CHANGES_REQUESTED = "changes_requested",
  CHANGES_STARTED = "changes_started",
  CHANGES_DONE = "changes_done",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
}

export enum InterviewStatus {
  SCHEDULED = "scheduled",
  NO_SHOW = "no_show",
  CANCELLED = "cancelled",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
}

export type Users = {
  id: number
  status: ApplicationStatus
  eligibleForInterview: boolean
  submitTime: string
  review: {
    id: number
    status: ReviewStatus
    reviewer: {
      fullName: string
      uuid: string
      email: string
    }
  } | null
  interview: {
    id: number
    status: InterviewStatus
  } | null
  applicationDetail: {
    id: number
    firstName: string
    lastName: string
    email: string
    photo: string
  }
  productId: number
  centreId: number
  productSlug: ApplicationTypeSlug
  evidenceUploaded: boolean
}

export type PhoneNumber = {
  countryCode: number
  number: number
}

export type Address = {
  id: number
  streetAddress: string
  country: Country
  state: State
  city: string
  pincode: string
  isCurrent: boolean
  isPermanent: boolean
}

export type Reference = {
  id: number
  phoneNumber: PhoneNumber
  name: string
  email: string
  relationship: string | null
  jobTitle: string | null
  workPlace: string | null
}

export type Option = {
  id: number
  text: string
  order: number
  additionalAnswerRequired: boolean
  additionalAnswerType: AdditionalAnswerType | null
}

export type Response = {
  id: number
  option: number
  additionalAnswer: string
  additionalAnswerFile?: string
}

export type Question = {
  id: number
  text: string
  slug: string
  questionType: QuestionType
  category: string
  isRequired: boolean
  options: Option[]
  responses: Response[]
}

export type PersonalDetails = {
  id: number
  title: number | null
  firstName: string
  lastName: string
  email: string
  dateOfBirth: string | null
  gender: string | undefined
  nationalIdNumber: string | null
  nationalityId: string | null
  mobilePhoneNumber: PhoneNumber | null
  photo: string | undefined
  passportNumber: string | null
  whatsappPhoneNumber: PhoneNumber | null
  homePhoneNumber: PhoneNumber | null
  workPhoneNumber: PhoneNumber | null
  currentResidence?: Address
  permanentResidence?: Address
  permanentSameAsCurrent: number
  addresses: Address[]
}

export type ApplicationDeclaration = {
  id: number
  declaration: string
  requiredDocuments?: string
  applicationDeclarationAttachment?: {
    id: number
    attachment: string
  }[]
}

export type ApplicantDetails = {
  id: number
  crmIdentifiers: {
    leadOwnerName: string
    leadOwnerEmail: string
  } | null
  attemptNumber: number
  productSlug: string
  addresses: Address[]
  references: Reference[]
  questionnaireQuestions: {
    questionNumber: number
    question: Question
  }[]
  centreId: number
  status: ApplicationStatus
  review: { id: number; status: ReviewStatus } | null
  interview: { id: number; status: InterviewStatus } | null
  eligibleForInterview: boolean
  applicationDetail: PersonalDetails
  submitTime?: string | null
  applicationDeclaration: ApplicationDeclaration[]
  reviewer: Reviewer | null
}

export type ValueOf<T> = T[keyof T]

export type EducationDetails = {
  course: string
  qualification: string | null
  domain: string | null
  institute: string
  city: string | null
  country: string | null
  startDate: string
  endDate: string | null
  qualificationFormat?: QualificationFormat
  evidences: Evidence[]
}[]

export enum QualificationFormat {
  REGULAR = 1,
  DISTANCE,
}

export enum ReviewChangeItemType {
  applicationdetail = "applicationdetail",
  applicationAddress = "applicationaddress",
  qualification = "academicqualification",
  certification = "academiccertification",
  workexperience = "workexperience",
  educationalbackground = "educationalbackground",
  languageproficiency = "languageproficiency",
}

export type CommentType = {
  itemId: number | null
  itemType: ReviewChangeItemType
  fieldName?: string
  comment: string | null
  error?: string
}

export type GeneralCommentType = {
  itemId: null
  itemType: ReviewChangeItemType
  comment: string | null
}

export type CheckListType = {
  personal?: CommentType[]
  education?: CommentType[]
  employment?: CommentType[]
  applicationDeclaration?: ApplicationDeclaration[]
}

export enum EvidenceType {
  URL = 1,
  File,
}

type BaseEvidence = {
  id: string
}

export type Evidence =
  | (BaseEvidence & {
      evidenceType: EvidenceType.URL
      url: string
    })
  | (BaseEvidence & {
      evidenceType: EvidenceType.File
      file: string
      fileName: string
    })

export type ReviewStatusType = {
  id: number
  status: ReviewStatus
  interviewStatus: InterviewStatus
}

export type Certificate = {
  id: number
  attachment: string | null
  attachmentName: string | null
  attachmentType: AttachmentType | null
  url: string | null
}

export type LanguageProficiency = {
  id: number
  language: Language
  proficiency: LanguageProficiencyChoices
  hasEvidence: boolean
  certificateName: string | null // Null if has evidence is false
  certificateIssuerName: string | null // Null if has evidence is false
  proficiencyScore: number | null // Null if has evidence is false
  attachmentName: string | null // Null if has evidence is false
  attachment: string | null // Null if has evidence is false
  url: string | null // Null if has evidence is false
  suraasaId: number | null
}

export type Qualification = {
  id: number
  name: string
  instituteName: string
  certificates: Certificate[]
  qualificationLevel: QualificationLevel
  qualificationField: QualificationField
  qualificationFormat?: QualificationFormat
  country: Country | null
  state: State | null
  grade: string | null
  modeOfLearning: ModeOfLearning
  startDate: string
  endDate: string
  suraasaId: number | null
}

export type Certification = {
  id: number
  name: string
  instituteName: string
  canExpire: boolean
  completionDate: string
  expirationDate: string | null
  modeOfLearning: ModeOfLearning
  country: Country | null
  state: State | null
  certificates: Certificate[]
  suraasaId: null
}

export type WorkExperience = {
  id: number
  title: string
  currentlyWorking: boolean
  startDate: string
  endDate: string | null
  subjects: {
    subjectId: string
  }[]
  employmentType: EmploymentType
  description: string | null
  organisationName: string
  organisationType: OrganisationType | null
  otherOrganisationType: string | null
  curriculum: Curriculum | null
  teachingLevel: TeachingLevel | null
  teachingRole: TeachingRole | null
  country: Country | null
  state: State | null
  currency: Currency | null
  salary: number
  suraasaId: number | null
}

export type ProfileDataType = {
  languageProficiencies: LanguageProficiency[]
  qualifications: Qualification[]
  certifications: Certification[]
  workExperiences: WorkExperience[]
}

export type Overview = {
  myApplications: number
  allApplications: number
  closedApplications: number
}

export type ApplicationTypeSlug = ValueOf<typeof applicationFormSlugs>
export type Reviewer = {
  fullName: string
  uuid: string
  email: string
}
