import { cn, TextField, Typography } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import clsx from "clsx"
import Navbar from "components/shared/Navbar/Navbar"
import PageContainer from "components/shared/PageContainer"
import useTabs, { Tabs as TabsType } from "components/shared/tabs/useTabs"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import useDebounce from "utils/hooks/useDebounce"
import { Search } from "utils/icons"

import AllApplications from "./AllApplications"
import ClosedApplications from "./ClosedApplications"
import { TabNames } from "./constants"
import MyApplications from "./MyApplications"
import AssignedTo from "./Tools/AssignedTo"
import Filter from "./Tools/Filter"
import SortBy from "./Tools/SortBy"

const initialFilter = "-submit_time"

const AdminDashboard = () => {
  const { register, watch, resetField } = useForm({
    defaultValues: {
      searchInput: "",
    },
  })
  const debouncedSearchValue = useDebounce(watch("searchInput"))
  const [tabs, setTabs] = useState<TabsType>([
    {
      name: TabNames.MyApplications,
      content: MyApplications,
    },
    {
      name: TabNames.AllApplications,
      content: AllApplications,
    },
    {
      name: TabNames.Closed,
      content: ClosedApplications,
    },
  ])
  const [sortBy, setSortBy] = useState<string>(initialFilter)
  const [filter, setFilter] = useState<string[]>([])
  const [assignedTo, setAssignedTo] = useState<string[]>([])
  const [activeTab, setActiveTab] = useTabs({ tabs, initialTab: tabs[1].name })

  const clearFilter = () => {
    setAssignedTo([])
    setFilter([])
  }

  const overview = useQuery({
    // Note: Didn't add query key so overviewData is not cached and it can fetch every time.
    queryFn: () => api.applications.overview(),
  })

  useEffect(() => {
    if (overview.isFetching === true) return
    if (overview.data) {
      const newTabs: TabsType = [
        {
          name: TabNames.MyApplications,
          content: MyApplications,
          count: overview.data.myApplications,
        },
        {
          name: TabNames.AllApplications,
          content: AllApplications,
          count: overview.data.allApplications,
        },
        {
          name: TabNames.Closed,
          content: ClosedApplications,
          count: overview.data.closedApplications,
        },
      ]
      setTabs(newTabs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overview.isSuccess, overview.isFetching])

  return (
    <>
      <Navbar gutterBottom={false} />

      <div
        className={clsx(
          "mb-2 border-b-2 border-onSurface-200 bg-white pt-3 shadow-[0px_6px_15px_rgba(0,0,0,0.05)]"
        )}
      >
        <PageContainer>
          <div className="mr-3 flex w-full gap-2.5 overflow-auto ">
            {tabs.map(item => (
              <button
                key={item.name}
                className={clsx("flex shrink-0 items-center gap-0.75 pb-1.5", {
                  "border-b-2 border-onSurface-900":
                    item.name === activeTab.name,
                })}
                onClick={() => {
                  resetField("searchInput")
                  setActiveTab(item.name)
                  clearFilter()
                }}
              >
                <Typography
                  variant={item.name === activeTab.name ? "strong" : "body"}
                  style={{
                    textDecorationThickness: "2px",
                  }}
                  className={clsx("cursor-pointer")}
                >
                  {item.name}{" "}
                </Typography>
                {item.count !== undefined && (
                  <span
                    className={cn(
                      "bg-onSurface-200 text-onSurface-500 rounded-[4px] px-0.5 text-xs font-medium leading-[15.6px] tracking-[-0.04em] ",
                      {
                        "bg-onSurface-900 text-white":
                          item.name === activeTab.name,
                      }
                    )}
                  >
                    {item.count}
                  </span>
                )}
              </button>
            ))}
          </div>
        </PageContainer>
      </div>

      <PageContainer>
        <div className="flex flex-col items-start justify-between gap-1 md:flex-row md:items-center">
          <TextField
            {...register("searchInput")}
            className="max-w-none sm:min-w-[336px] md:max-w-[500px] [&>div]:overflow-hidden [&>div]:border-onSurface-300"
            rounded
            startIcon={
              <Search className="text-onSurface-400" height={20} width={20} />
            }
            placeholder="Search"
          />

          <div className="flex flex-wrap items-center gap-2">
            {activeTab.name === TabNames.AllApplications && (
              <AssignedTo filter={assignedTo} setFilter={setAssignedTo} />
            )}
            <Filter
              filter={filter}
              activeTabName={activeTab.name}
              setFilter={setFilter}
            />
            <SortBy
              sortBy={sortBy}
              activeTabName={activeTab.name}
              setSortBy={setSortBy}
            />
          </div>
        </div>
      </PageContainer>

      <PageContainer className="pb-6 pt-2">
        <activeTab.content
          sortBy={sortBy}
          search={debouncedSearchValue}
          filter={filter}
          assignedTo={assignedTo}
        />
      </PageContainer>
    </>
  )
}

export default AdminDashboard
